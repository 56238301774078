document.querySelectorAll('form.cart, form.woocommerce-cart-form').forEach(function (form) {
  form.parentElement.addEventListener('click', function (event) {
    console.log('clickeddd')
    if (event.target.matches('button.plus, button.minus')) {
      window.lol = event.target
      var qty = event.target.parentElement.querySelector('input[type="number"]')
      var val = parseFloat(qty.value)
      var max = parseFloat(qty.getAttribute('max'))
      var min = parseFloat(qty.getAttribute('min'))
      var step = parseFloat(qty.getAttribute('step'))
      var newValue = val
      if (event.target.classList.contains('plus')) {
        if (max && max <= val) {
          newValue = max
        } else {
          newValue = val + step
        }
      } else {
        if (min && min >= val) {
          newValue = min
        } else if (val > 1) {
          newValue = val - step
        }
      }
      if (newValue !== val) {
        qty.value = newValue
        qty.dispatchEvent(new Event('input', { bubbles: true }))
      }
    }
  })
})
