document.addEventListener('DOMContentLoaded', () => {
  var summary = document.querySelector('.summary')
  // Get the tabs element
  var tabs = document.querySelector('.custom-woo-tabs')
  if (!tabs) {
    return
  }

  // Calculate the top position based on the summary's height
  var topPosition = summary.offsetHeight > 800 ? '70%' : '52%'
  console.log(summary.offsetHeight)

  // Apply the top position to the tabs
  tabs.style.top = topPosition
})
