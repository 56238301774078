document.addEventListener('DOMContentLoaded', function () {
  // Add attribute name after image
  function addAttributeNameAfterImage() {
    const imageItems = document.querySelectorAll('.image-variable-item')

    imageItems.forEach((item) => {
      const attributeName = item.parentElement.getAttribute('data-attribute_name')

      if (attributeName === 'attribute_pa_utforande') {
        const dataValue = item.getAttribute('data-value')

        const imageContents = item.querySelector('.variable-item-contents')
        const attributeNameElement = document.createElement('span')

        attributeNameElement.classList.add('image-title')
        attributeNameElement.textContent = dataValue
        imageContents.appendChild(attributeNameElement)
      }
    })
  }
  addAttributeNameAfterImage()
})
